import clsx from 'clsx';
import {
  cloneElement,
  FC,
  FocusEvent,
  MouseEvent,
  ReactElement,
  ReactNode,
  RefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {useOnClickOutside} from 'usehooks-ts';
import {Tooltip} from '../../../components/Tooltip/Tooltip';
import {TestableElement} from '../../../external/types';
import {LabelProps} from '../Label/Label';

export enum TogglableSize {
  SM = 'SM',
  MD = 'MD',
}

export type TogglableAppearanceProps = {
  isChecked?: boolean;
  moderate?: boolean;
  size?: TogglableSize;
  isError?: boolean;
  disabled?: boolean;
};

export enum TogglableLabelPosition {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export type TogglableProps = {
  label?: string | ReactElement<LabelProps>;
  labelPosition?: TogglableLabelPosition;
  checked?: boolean;
  defaultChecked?: boolean;
  moderate?: boolean;
  onChange: (state: boolean, event?: MouseEvent<HTMLInputElement>) => void;
  className?: string;
  size?: TogglableSize;
  isError?: boolean;
  disabled?: boolean;
  value: string | number;
  name?: string;
  ordinal?: number;
  isRadio?: boolean;
  required?: boolean;
  appearance: ReactElement<TogglableAppearanceProps>;
  getAppearanceInputStyles: (size: TogglableSize) => string;
  onBlur?: (event: FocusEvent<HTMLElement>) => void;
  testElement: string;
  tooltipText?: ReactNode;
} & TestableElement & {componentRef?: RefObject<HTMLDivElement>};

export const Togglable: FC<TogglableProps> = ({
  labelPosition = TogglableLabelPosition.RIGHT,
  label = '',
  checked = undefined,
  moderate = false,
  onChange,
  className = '',
  size = TogglableSize.MD,
  isError = false,
  disabled = false,
  defaultChecked = undefined,
  value,
  appearance,
  getAppearanceInputStyles,
  name = undefined,
  ordinal = 0,
  isRadio = false,
  required = false,
  onBlur = undefined,
  testId = undefined,
  componentRef = undefined,
  testElement,
  tooltipText = undefined,
}) => {
  const labelRef = useRef<HTMLLabelElement>(null);
  const isFocusIn = useRef<boolean>(false);

  useOnClickOutside(labelRef, event => {
    if (isFocusIn.current === true) {
      isFocusIn.current = false;
      if (onBlur) {
        onBlur(event as unknown as FocusEvent<HTMLElement>);
      }
    }
  });

  const inputName = useMemo(() => name || crypto.randomUUID(), []);
  const inputId = `${inputName}${ordinal}`;
  const [isChecked, setIsChecked] = useState(defaultChecked !== undefined ? defaultChecked : checked || false);
  const handleClick = (nextState: boolean, event?: MouseEvent<HTMLInputElement>) => {
    isFocusIn.current = true;

    if (checked === undefined) {
      setIsChecked(nextState);
    }

    onChange(nextState, event);
  };

  useEffect(() => {
    setIsChecked(checked || false);
  }, [checked]);

  useEffect(() => {
    if (defaultChecked !== undefined && checked !== undefined) {
      console.error(`Symfonia: checkbox posiada ustawione wartości checked i defaultChecked`);
    }
  }, [defaultChecked, checked]);

  useEffect(() => {
    if (moderate === true && defaultChecked !== undefined) {
      console.error(`Symfonia: tryb "moderate" dostępny tylko dla kontrolowanego stanu komponentu`);
    }
  }, [moderate]);

  const styles = {
    component: clsx('group inline-flex w-fit relative items-center', {
      'flex-row-reverse': labelPosition === TogglableLabelPosition.LEFT,
    }),
    appearance: 'inline-flex relative',
    input: clsx(
      'appearance-none absolute pointer-events-none focus-visible:outline-none focus-visible:ring-[3px] peer',
      getAppearanceInputStyles(size),
      {
        'focus-visible:ring-primary-600': !isError && !disabled,
        'focus-visible:ring-red-600': isError && !disabled,
      },
    ),
    inputElement: clsx(
      'block outline-none peer-focus-visible:ring-[2px] peer-focus-visible:ring-white',
      getAppearanceInputStyles(size),
    ),
    label: clsx('relative flex select-none items-center ', {
      'cursor-pointer': !disabled,
    }),
    span: clsx('font-quicksand', {
      'before:content-["*"] before:text-red-500 before:text-2xl whitespace-pre-wrap flex items-center gap-[4px]':
        required,
      'group-hover:text-primary-600': !disabled && !isError,
      'group-hover:text-red-600': !disabled && isError,
      'text-grey-500': disabled,
      'text-red-500': !disabled && isError,
      'text-base': size === TogglableSize.MD,
      'text-sm': size === TogglableSize.SM,
      'ml-[8px]': labelPosition === TogglableLabelPosition.RIGHT,
      'mr-[8px]': labelPosition === TogglableLabelPosition.LEFT,
    }),
  };

  const Appearance = useMemo(
    () => () =>
      cloneElement<TogglableAppearanceProps>(appearance, {
        isChecked,
        isError,
        disabled,
        moderate,
        size,
      }),
    [isChecked, isError, disabled, moderate, size],
  );

  return (
    <div
      ref={componentRef}
      className={clsx(className, styles.component)}
      data-test-element={testElement}
      data-testid={testId}
    >
      <input
        id={inputId}
        type={isRadio ? 'radio' : 'checkbox'}
        className={styles.input}
        checked={isChecked}
        onClick={event => !disabled && handleClick(moderate || isRadio ? true : !isChecked, event)}
        value={value}
        readOnly
        name={inputName}
      />
      <div className={styles.inputElement} />
      <label ref={labelRef} htmlFor={inputId} className={styles.label}>
        {labelPosition === TogglableLabelPosition.LEFT && <span className={styles.span}>{label}</span>}
        <Tooltip text={tooltipText}>
          <span className={styles.appearance}>
            <Appearance />
          </span>
        </Tooltip>
        {labelPosition === TogglableLabelPosition.RIGHT && label && (
          <span className={styles.span}>
            {required && ' '}
            {label}
          </span>
        )}
      </label>
    </div>
  );
};
