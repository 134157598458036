import {IconSize, Tag, TagProps} from '@symfonia/brandbook';
import React from 'react';
import {IconType, IconWithTooltip} from '../IconWithTooltip/IconWithTooltip';


interface TagWithTooltipProps extends TagProps {
  text: string;
  tooltipContent: string;
  tagClassName?: string;
  iconClassName?: string;
  containerClass?: string;
  tooltipIcon: IconType;
  tooltipIconSize?: IconSize,
}

export const TagWithTooltip = ({text, tagClassName, containerClass, tooltipContent, tooltipIconSize, tooltipIcon, iconClassName, ...tagProps}: TagWithTooltipProps) => {

  return (
    <div className={`relative w-fit ${containerClass}`}>
      <Tag
        className={`whitespace-nowrap pr-8 ${tagClassName}`}
        text={text}
        {...tagProps}
      />
      <IconWithTooltip
        tooltipContent={tooltipContent}
        size={tooltipIconSize ?? IconSize.SM}
        icon={tooltipIcon}
        btnClass={`flex items-center absolute right-2 top-1/2 -translate-y-1/2 ${iconClassName}`}
      />
    </div>
  );
};