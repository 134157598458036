export const isEllipsis = (el: HTMLElement, nested = false): boolean => {
  return (
    el.clientWidth !== el.scrollWidth ||
    el.offsetHeight < el.scrollHeight ||
    (nested &&
      Array.from(el.children).some(nel => {
        return isEllipsis(nel as HTMLElement, true);
      }))
  );
};
