import clsx from 'clsx';
import dayjs from 'dayjs';
import {FC, ReactNode, useEffect, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import {twMerge} from 'tailwind-merge';
import '../../../../tailwind.utilities.css';
import {renderCalendarNavBar} from '../../../external/elements/Calendar/Calendar.elements';
import {
  CalendarDateRange,
  CalendarMonthAndYear,
  CalendarView,
  DateObj,
  EditedDateType,
  dateObjToDate,
  dateToDateObj,
  getDecade,
  getDecadeMatrix,
  getMonthIndex,
  getMonthMatrix,
  getOffsetMonth,
  isBetweenDates,
  isSameDay,
  mondayAsAFirstDayOfAWeek,
  monthMatrix,
  tMonths,
  tShortDays,
  isWithinDates,
} from '../../../external/elements/Calendar/Calendar.helper';
import {calendarT} from '../../../external/elements/Calendar/Calendar.t';
import {TestableElement} from '../../../external/types';

export type AllowedCalendarDateRange = {
  from?: Date | DateObj;
  to?: Date | DateObj;
};

export type CalendarCoreProps = {
  onChange: (output: DateObj | CalendarDateRange) => void;

  today?: Date | DateObj;
  selected?: Date | DateObj | null;

  range?: boolean;
  selectedEnd?: Date | DateObj | null;
  minDate?: Date | DateObj;
  maxDate?: Date | DateObj;
  allowedDates?: AllowedCalendarDateRange[];

  className?: string;

  /** Props for 2-months calendarView */
  showTodayButton?: boolean;
  showFormerDays?: boolean;
  showLatterDays?: boolean;
  onViewChange?: (nextView: CalendarView) => void;
  view?: CalendarView;
  onSelectedChange?: (date: Date | null) => void;
  onSelectedEndChange?: (date: Date | null) => void;
  onHoverChange?: (date: Date | null) => void;
  onEditedDateTypeChange?: (type: EditedDateType | null) => void;
  updateHoverDate?: Date | null;
  updateEditedDateType?: EditedDateType | null;

  /** Calendar Control */
  onMonthToDisplayChange?: (month: number) => void;
  onYearToDisplayChange?: (year: number) => void;
  updateMonthToDisplay?: number;
  updateYearToDisplay?: number;

  /** Highlights (for month/year selector) */
  useHighlights?: boolean;
  onHighlightedMonthChange?: (my: CalendarMonthAndYear) => void;
  onHighlightedYearChange?: (y: number) => void;
  updateHighlightedMonth?: CalendarMonthAndYear;
  updateHighlightedYear?: number;
  viewMode?: CalendarView;

  additionalNavbarHeaderContent?: ReactNode;

  testElement?: string;
} & TestableElement;

export const CalendarCore: FC<CalendarCoreProps> = ({
  today = undefined,
  range = false,
  selected = null,
  selectedEnd = null,
  onChange = () => undefined,
  minDate = undefined,
  maxDate = undefined,
  allowedDates = undefined,
  className = '',

  showFormerDays = true,
  showLatterDays = true,
  showTodayButton = true,
  view = undefined,
  viewMode = undefined,
  onViewChange = () => undefined,
  onSelectedChange = () => undefined,
  onSelectedEndChange = () => undefined,
  onHoverChange = () => undefined,
  updateHoverDate = undefined,
  onEditedDateTypeChange = () => undefined,
  updateEditedDateType = undefined,
  onMonthToDisplayChange = () => undefined,
  updateMonthToDisplay = undefined,
  onYearToDisplayChange = () => undefined,
  updateYearToDisplay = undefined,
  updateHighlightedMonth = undefined,
  updateHighlightedYear = undefined,
  onHighlightedMonthChange = () => undefined,
  onHighlightedYearChange = () => undefined,
  useHighlights = false,
  additionalNavbarHeaderContent = undefined,
  testId = undefined,
  testElement = 'calendar',
}) => {
  const myAllowedDates = useMemo<AllowedCalendarDateRange[]>(() => {
    if (allowedDates && [minDate, maxDate].some(v => !!v)) {
      console.error('Symfonia/Brandbook/CalendarCore: użyj allowedDates LUB minDate/maxDate');
    }

    return allowedDates || [{from: minDate, to: maxDate}];
  }, [allowedDates, minDate, maxDate]);
  const intl = useIntl();
  const [editedDateType, _setEditedDateType] = useState<EditedDateType | null>(null);
  const setEditedDateType = (type: EditedDateType | null) => {
    _setEditedDateType(type);
    onEditedDateTypeChange(type);
  };

  useEffect(() => {
    if (updateEditedDateType !== undefined && updateEditedDateType !== editedDateType) {
      _setEditedDateType(updateEditedDateType);
    }
  }, [updateEditedDateType]);

  const todayDate = useMemo(() => {
    if (today === undefined) {
      return new Date();
    }
    return today instanceof Date ? today : new Date(today.year, today.month - 1, today.day);
  }, [today]);

  const [calendarView, _setCalendarView] = useState<CalendarView>(viewMode || view || CalendarView.Calendar);
  const setCalendarView = (nextViewProposal: CalendarView): void => {
    const nextView = Math.max(nextViewProposal, viewMode || CalendarView.Calendar) as CalendarView;
    onViewChange(nextView);
    _setCalendarView(nextView);
  };

  useEffect(() => {
    if (view !== undefined) {
      _setCalendarView(view);
    }
  }, [view]);

  const [hoverDate, _setHoverDate] = useState<Date | null>(null);
  const setHoverDate = (date: Date | null) => {
    _setHoverDate(date);
    onHoverChange(date);
  };

  useEffect(() => {
    if (updateHoverDate !== undefined && updateHoverDate !== hoverDate) {
      _setHoverDate(updateHoverDate);
    }
  }, [updateHoverDate]);

  const [selectedDate, _setSelectedDate] = useState<Date | null>(() => {
    if (selected === undefined || selected === null) {
      return null;
    }
    return dateObjToDate(selected);
  });

  useEffect(() => {
    _setSelectedDate(selected ? dateObjToDate(selected) : null);
  }, [selected]);

  const [selectedEndDate, _setSelectedEndDate] = useState<Date | null>(() => {
    if (selectedEnd === undefined || selectedEnd === null) {
      return null;
    }
    return selectedEnd instanceof Date
      ? selectedEnd
      : new Date(selectedEnd.year, selectedEnd.month - 1, selectedEnd.day);
  });

  useEffect(() => {
    _setSelectedEndDate(selectedEnd ? dateObjToDate(selectedEnd) : null);
  }, [selectedEnd]);

  const setSelectedDate = (date: Date | null): void => {
    if (date === null) {
      _setSelectedDate(date);
      return;
    }

    if (selectedDate === null || date.toLocaleDateString() !== selectedDate.toLocaleDateString()) {
      _setSelectedDate(date);
      if (range === false) {
        onChange(dateToDateObj(date));
      }
    }

    onSelectedChange(date);
  };

  const setSelectedEndDate = (date: Date | null) => {
    _setSelectedEndDate(date);

    if (range === true && date !== null && selectedDate !== null) {
      const eventPayload = {
        date: {
          day: selectedDate.getDate(),
          month: selectedDate.getMonth() + 1,
          year: selectedDate.getFullYear(),
        },
        endDate: {
          day: date.getDate(),
          month: date.getMonth() + 1,
          year: date.getFullYear(),
        },
      };
      onChange(eventPayload);
    }
    onSelectedEndChange(date);
  };

  const isAllowedDate = (date: Date, threshold: 'day' | 'month' | 'year' = 'day') => {
    return myAllowedDates.some(({from, to}) =>
      isWithinDates(date, from && dateObjToDate(from), to && dateObjToDate(to), threshold),
    );
  };

  const onDateClick = (date: Date) => {
    if (!isAllowedDate(date)) {
      return;
    }
    if (range === false) {
      setSelectedDate(date);
    } else if ([EditedDateType.DATE, null].includes(editedDateType)) {
      setSelectedDate(date);
      setSelectedEndDate(null);
      setEditedDateType(EditedDateType.END_DATE);
    } else if (editedDateType === EditedDateType.END_DATE) {
      if (selectedDate !== null && date.getTime() < selectedDate.getTime()) {
        setSelectedDate(date);
      } else {
        setSelectedEndDate(date);
        setEditedDateType(null);
      }
    }
  };

  const [yearToDisplay, _setYearToDisplay] = useState<number>(
    updateYearToDisplay || (selectedDate && selectedDate.getFullYear()) || todayDate.getFullYear(),
  );

  const setYearToDisplay = (year: number) => {
    onYearToDisplayChange(year);
    _setYearToDisplay(year);
  };

  const changeYearToDisplay = (year: number): void => {
    setYearToDisplay(year);
    setCalendarView(CalendarView.MonthSelector);
  };

  useEffect(() => {
    if (updateYearToDisplay !== undefined && yearToDisplay !== updateYearToDisplay) {
      _setYearToDisplay(updateYearToDisplay);
    }
  }, [updateYearToDisplay]);

  const [monthToDisplay, _setMonthToDisplay] = useState<number>(() => {
    if (updateMonthToDisplay !== undefined) {
      return updateMonthToDisplay;
    }

    if (selectedDate) {
      return selectedDate.getMonth();
    }

    return todayDate.getMonth();
  });

  const setMonthToDisplay = (monthIndex: number) => {
    onMonthToDisplayChange(monthIndex);
    _setMonthToDisplay(monthIndex);
  };

  const changeMonthToDisplay = (monthIndex: number): void => {
    setMonthToDisplay(monthIndex);
    setCalendarView(CalendarView.Calendar);
  };

  useEffect(() => {
    if (updateMonthToDisplay !== undefined && monthToDisplay !== updateMonthToDisplay) {
      _setMonthToDisplay(updateMonthToDisplay);
    }
  }, [updateMonthToDisplay]);

  const setDateToDisplay = (date: Date): void => {
    setMonthToDisplay(date.getMonth());
    setYearToDisplay(date.getFullYear());
  };

  const [highlightedMonth, _setHighlightedMonth] = useState<CalendarMonthAndYear | void>(updateHighlightedMonth);

  const setHighlightedMonth = (monthIndex: number) => {
    onHighlightedMonthChange({month: monthIndex, year: yearToDisplay});
    _setHighlightedMonth({month: monthIndex, year: yearToDisplay});
  };

  useEffect(() => {
    if (updateHighlightedMonth !== undefined) {
      _setHighlightedMonth(updateHighlightedMonth);
    }
  }, [updateHighlightedMonth]);

  const [highlightedYear, _setHighlightedYear] = useState<number | void>(updateHighlightedYear);

  const setHighlightedYear = (year: number) => {
    onHighlightedYearChange(year);
    _setHighlightedYear(year);
  };

  useEffect(() => {
    if (updateHighlightedYear !== undefined) {
      _setHighlightedYear(updateHighlightedYear);
    }
  }, [updateHighlightedYear]);

  const matrix = useMemo<Date[][]>(
    () => mondayAsAFirstDayOfAWeek(getMonthMatrix(new Date(yearToDisplay, monthToDisplay), 6)),
    [monthToDisplay, yearToDisplay],
  );

  const onTodayClick = () => {
    setSelectedDate(todayDate);
    setMonthToDisplay(todayDate.getMonth());
    setYearToDisplay(todayDate.getFullYear());
    if (range) {
      setSelectedEndDate(null);
      setEditedDateType(EditedDateType.END_DATE);
    }
  };

  const isSelectableDate = (dayDate: Date | DateObj) => {
    return isAllowedDate(dateObjToDate(dayDate), 'day');
  };

  const isSelectableMonth = (date: Date | DateObj) => {
    return isAllowedDate(dateObjToDate(date), 'month');
  };

  const isSelectableYear = (year: number) => {
    return isAllowedDate(dayjs().year(year).toDate(), 'year');
  };

  const isRangeStart = (dayDate: Date) =>
    range &&
    selectedDate &&
    isSameDay(dayDate, selectedDate) &&
    (selectedEndDate || hoverDate) &&
    (selectedEndDate?.getTime() || (hoverDate !== null ? hoverDate.getTime() > selectedDate.getTime() : false));

  const isRangeSpan = (dayDate: Date) =>
    range &&
    selectedDate &&
    ((editedDateType === EditedDateType.END_DATE && hoverDate) || selectedEndDate) &&
    isBetweenDates(
      dayDate,
      selectedDate,
      ((editedDateType === EditedDateType.END_DATE && hoverDate) || selectedEndDate) as Date,
    ) &&
    ((editedDateType === EditedDateType.END_DATE && hoverDate) || selectedEndDate);

  const isRangeEnd = (dayDate: Date) => {
    const hoverOrSelectedEndDate = editedDateType === EditedDateType.END_DATE ? hoverDate : selectedEndDate;

    return (
      range &&
      hoverOrSelectedEndDate &&
      isSameDay(dayDate, hoverOrSelectedEndDate) &&
      selectedDate &&
      hoverOrSelectedEndDate.getTime() > selectedDate.getTime()
    );
  };

  const isSelectedDay = (dayDate: Date) =>
    (selectedDate && isSameDay(selectedDate, dayDate)) ||
    (range && editedDateType !== EditedDateType.END_DATE && selectedEndDate && isSameDay(selectedEndDate, dayDate));

  const styleTodayButtonBox = 'border-t-grey-100 border-t-solid border-t h-[2.5em] flex justify-center items-center';
  const styleTodayButton = 'text-primary-500 border-none text-base font-bold cursor-pointer';
  const styleMonthSelector = 'flex flex-col justify-center items-center h-full';
  const styleMonthSelectorRow = 'flex justify-center items-center grow shrink w-full';
  const styleMonthSelectorCellCurrent = 'border border-primary-500 border-solid';
  const styleMonthSelectorCellSelected = 'border border-primary-500 border-solid bg-primary-500 text-white';
  const styleMonthSelectorCellDisabled = 'cursor-default text-grey-300 opacity-[0.2]';

  const renderCalendarBody = () => (
    <>
      <div className="px-[12px] py-[4px] w-full">
        <div className="w-full flex">
          {tShortDays.map(tDay => (
            <span
              className="curspor-pointer font-[600] text-[12px] flex justify-center items-center grow shrink aspect-[21/18] w-[0.141%]"
              key={tDay.id}
            >
              {intl.formatMessage(tDay)}
            </span>
          ))}
        </div>
        {matrix.map((week, weekIndex) => (
          <div className="w-full flex" key={JSON.stringify(week.map(date => date.toLocaleDateString()))}>
            {week.map(dayDate => (
              <span
                tabIndex={0}
                role="button"
                onClick={() => onDateClick(dayDate)}
                onMouseEnter={() => setHoverDate(dayDate)}
                onMouseLeave={() => setHoverDate(null)}
                className={clsx(
                  'curspor-pointer flex justify-center items-center grow shrink aspect-[21/18] w-[0.141%]',
                  {
                    invisible:
                      (!showFormerDays && dayDate.getMonth() === monthToDisplay - 1) ||
                      (!showLatterDays && dayDate.getMonth() === monthToDisplay + 1),
                  },
                )}
                key={JSON.stringify(dayDate.toLocaleDateString())}
                data-date={dayDate.toLocaleDateString()}
              >
                <span
                  className={clsx({
                    'w-full flex items-center justify-center': true,
                    'bg-gradient-to-r from-transparent via-transparent to-primary-100': isRangeStart(dayDate),
                    'bg-gradient-to-r from-primary-100 via-transparent to-transparent': isRangeEnd(dayDate),
                    'bg-primary-100': isRangeSpan(dayDate),
                    'opacity-[0.2] cursor-default': !isSelectableDate(dayDate),
                  })}
                >
                  <span
                    className={clsx('box-border flex items-center justify-center text-sm w-[28px] h-[28px]', {
                      'bg-primary-400 text-white rounded-[4px] border-0':
                        hoverDate && isSameDay(hoverDate, dayDate) && isSelectableDate(dayDate),
                      'border border-primary-500 border-solid rounded-[4px]':
                        isSameDay(todayDate, dayDate) && !isRangeSpan(dayDate),
                      'text-grey-900': monthToDisplay === dayDate.getMonth() && !isSelectedDay(dayDate),
                      'text-grey-500 cursor-default pointer-events-none':
                        monthToDisplay !== dayDate.getMonth() && !isSelectedDay(dayDate),
                      'border border-primary-500, border-solid rounded-[4px] bg-primary-500 text-white':
                        isSelectedDay(dayDate),
                    })}
                  >
                    {dayDate.getDate()}
                  </span>
                </span>
              </span>
            ))}
          </div>
        ))}
      </div>
      {showTodayButton && (
        <div className={styleTodayButtonBox}>
          <button className={styleTodayButton} type="button" onClick={onTodayClick}>
            {intl.formatMessage(calendarT.dzisiaj)}
          </button>
        </div>
      )}
    </>
  );

  const renderMonthSelectorBody = () => (
    <div className={styleMonthSelector}>
      {monthMatrix.map(monthRow => (
        <div className={styleMonthSelectorRow} key={JSON.stringify(monthRow)}>
          {monthRow.map(month => (
            <span className={clsx('flex justify-center items-center grow shrink w-4/12 h-[26px]', {})} key={month.id}>
              <span
                role="button"
                tabIndex={0}
                onClick={() => {
                  if (
                    !isSelectableMonth(
                      new Date(
                        yearToDisplay,
                        tMonths.findIndex(m => m === month),
                      ),
                    )
                  )
                    return;

                  setHighlightedMonth(getMonthIndex(month));
                  changeMonthToDisplay(getMonthIndex(month));
                }}
                className={twMerge(
                  clsx('px-[16px] rounded-[4px] cursor-pointer', {
                    [styleMonthSelectorCellCurrent]:
                      todayDate.getFullYear() === yearToDisplay &&
                      todayDate.getMonth() === tMonths.findIndex(m => m === month),
                    [styleMonthSelectorCellSelected]:
                      (!useHighlights &&
                        selectedDate &&
                        selectedDate.getFullYear() === yearToDisplay &&
                        selectedDate.getMonth() === tMonths.findIndex(m => m === month)) ||
                      (!useHighlights &&
                        selectedEndDate &&
                        selectedEndDate.getFullYear() === yearToDisplay &&
                        selectedEndDate.getMonth() === tMonths.findIndex(m => m === month)) ||
                      (useHighlights &&
                        highlightedMonth?.month === tMonths.findIndex(m => m === month) &&
                        highlightedMonth?.year === yearToDisplay),
                    [styleMonthSelectorCellDisabled]: !isSelectableMonth(
                      new Date(
                        yearToDisplay,
                        tMonths.findIndex(m => m === month),
                      ),
                    ),
                  }),
                )}
              >
                {intl.formatMessage(month).substring(0, 3)}
              </span>
            </span>
          ))}
        </div>
      ))}
    </div>
  );

  const renderYearSelectorBody = () => (
    <div className={styleMonthSelector}>
      {getDecadeMatrix(yearToDisplay).map(yearRow => (
        <div className={styleMonthSelectorRow} key={JSON.stringify(yearRow)}>
          {yearRow.map(year => (
            <span className="flex justify-center items-center grow shrink w-4/12" key={year}>
              <span
                tabIndex={0}
                role="button"
                onClick={() => {
                  changeYearToDisplay(year);
                  setHighlightedYear(year);
                }}
                className={clsx('px-[16px] rounded-[4px] cursor-pointer', {
                  [styleMonthSelectorCellCurrent]: todayDate.getFullYear() === year,
                  [styleMonthSelectorCellSelected]:
                    (!useHighlights && selectedDate && selectedDate.getFullYear() === year) ||
                    (!useHighlights && selectedEndDate && selectedEndDate.getFullYear() === year) ||
                    (useHighlights && (highlightedMonth ? highlightedMonth.year === year : highlightedYear === year)),
                  [styleMonthSelectorCellDisabled]: !isSelectableYear(year),
                })}
              >
                {year}
              </span>
            </span>
          ))}
        </div>
      ))}
    </div>
  );

  const render = () => (
    <div
      className={clsx(
        'bg-white max-w-max w-fit h-fit min-h-[280px] font-quicksand flex flex-col select-none',
        className,
      )}
      data-testid={testId}
      data-test-element={testElement}
    >
      <>
        {calendarView === CalendarView.Calendar &&
          renderCalendarNavBar({
            onDoubleArrowLeft: () => setYearToDisplay(yearToDisplay - 1),
            onArrowLeft: () => setDateToDisplay(getOffsetMonth(new Date(yearToDisplay, monthToDisplay), -1)),
            onLabel: () => setCalendarView(CalendarView.MonthSelector),
            labels: [
              {
                text: `${intl.formatMessage(tMonths[monthToDisplay])} ${yearToDisplay}`,
                key: `${intl.formatMessage(tMonths[monthToDisplay])} ${yearToDisplay}`,
              },
            ],
            onArrowRight: () => setDateToDisplay(getOffsetMonth(new Date(yearToDisplay, monthToDisplay), 1)),
            onDoubleArrowRight: () => setYearToDisplay(yearToDisplay + 1),
            additionalHederContent: additionalNavbarHeaderContent,
          })}
        {calendarView === CalendarView.MonthSelector &&
          renderCalendarNavBar({
            onArrowLeft: () => setYearToDisplay(yearToDisplay - 1),
            onLabel: () => setCalendarView(CalendarView.YearSelector),
            labels: [{text: `${yearToDisplay}`, key: `${yearToDisplay}`}],
            onArrowRight: () => setYearToDisplay(yearToDisplay + 1),
          })}
        {calendarView === CalendarView.YearSelector &&
          renderCalendarNavBar({
            onArrowLeft: () => setYearToDisplay(yearToDisplay - 10),
            labels: [
              {
                text: `${getDecade(yearToDisplay)} - ${getDecade(yearToDisplay) + 9}`,
                key: `${getDecade(yearToDisplay)} - ${getDecade(yearToDisplay) + 9}`,
              },
            ],
            onArrowRight: () => setYearToDisplay(yearToDisplay + 10),
          })}
        <div className="grow shrink w-[280px] basis-0">
          {calendarView === CalendarView.Calendar && renderCalendarBody()}
          {calendarView === CalendarView.MonthSelector && renderMonthSelectorBody()}
          {calendarView === CalendarView.YearSelector && renderYearSelectorBody()}
        </div>
      </>
    </div>
  );

  return render();
};
