import clsx from 'clsx';
import {FC} from 'react';
import {twMerge} from 'tailwind-merge';
import '../../../tailwind.utilities.css';
import {TestableElement} from '../../external/types';
import {ChildComponentWithDropdown, ComponentWithDropdown, WithDropdownList} from '../Dropdown/WithDropdownList';
import {Icon, IconProps, IconSize, IconSvg, IconVariant} from '../Icon/Icon';

export enum ButtonTertiaryVariant {
  GREEN = 'GREEN',
  RED = 'RED',
}

export enum ButtonTertiarySize {
  SM = 'SM',
  MD = 'MD',
}

export enum ButtonTertiaryWidth {
  FULL = 'FULL',
  FIT = 'FIT',
  BASE = 'BASE',
  INITIAL = 'INITIAL',
}

export type ButtonTertiaryConfig = {
  variant: ButtonTertiaryVariant;
  size: ButtonTertiarySize;
  width: ButtonTertiaryWidth;
};

export type ButtonTertiaryProps = Partial<ButtonTertiaryConfig> & {
  lIcon?: IconSvg | null;
  rIcon?: IconSvg | null;
  iconOptions?: Omit<IconProps, 'svg'>;
  stickyIcons?: boolean;
  text?: string;
  onClick?: (() => void) | (() => Promise<void>);
  className?: string;
  disabled?: boolean;
  loading?: boolean;
} & TestableElement;

type ButtonStyles = {
  component: string;
  icon: string;
  text: string;
};

const getStyles = (
  config: ButtonTertiaryConfig & {
    disabledOrLoading: boolean;
    hasText: boolean;
    isClickable: boolean;
    stickyIcons: boolean;
  },
): ButtonStyles => {
  return {
    component: clsx(
      'relative group whitespace-nowrap inline-flex justify-center items-center rounded select-none focus-visible:outline focus-visible:outline-[1px] p-[4px] active:bg-transparent',
      {
        'w-full': config.hasText && config.width === ButtonTertiaryWidth.FULL,
        'min-w-[200px]': config.hasText && config.width === ButtonTertiaryWidth.BASE,
        'w-fit': config.hasText && config.width === ButtonTertiaryWidth.FIT,
        'h-[24px] min-w-[24px]': config.size === ButtonTertiarySize.SM,
        'h-[28px] min-w-[28px]': config.size === ButtonTertiarySize.MD,
        'cursor-pointer': !config.disabledOrLoading && config.isClickable,
        'focus-visible:outline-primary-600 hover:bg-primary-50':
          config.variant === ButtonTertiaryVariant.GREEN && !config.disabledOrLoading,
        'focus-visible:outline-red-600 hover:bg-red-50':
          config.variant === ButtonTertiaryVariant.RED && !config.disabledOrLoading,
      },
    ),
    icon: clsx({
      'filter-grey-300': config.disabledOrLoading,
      'filter-primary-500': !config.disabledOrLoading && config.variant === ButtonTertiaryVariant.GREEN,
      'filter-red-500': !config.disabledOrLoading && config.variant === ButtonTertiaryVariant.RED,
      'group-hover:filter-primary-600 group-focus:filter-primary-500 group-active:filter-primary-700':
        !config.disabledOrLoading && config.isClickable && config.variant === ButtonTertiaryVariant.GREEN,
      'group-hover:filter-red-600 group-focus:filter-red-500 group-active:filter-red-700':
        !config.disabledOrLoading && config.isClickable && config.variant === ButtonTertiaryVariant.RED,
    }),
    text: clsx('font-quicksand font-bold', {
      grow: config.stickyIcons,
      'text-grey-300': config.disabledOrLoading,
      'text-primary-500': !config.disabledOrLoading && config.variant === ButtonTertiaryVariant.GREEN,
      'text-red-500': !config.disabledOrLoading && config.variant === ButtonTertiaryVariant.RED,
      'group-hover:text-primary-600 group-focus:text-primary-500 group-active:text-primary-700':
        !config.disabledOrLoading && config.isClickable && config.variant === ButtonTertiaryVariant.GREEN,
      'group-hover:text-red-600 group-focus:text-red-500 group-active:text-red-700':
        !config.disabledOrLoading && config.isClickable && config.variant === ButtonTertiaryVariant.RED,
      'text-sm': config.size === ButtonTertiarySize.SM,
      'text-base': config.size === ButtonTertiarySize.MD,
    }),
  };
};

const ButtonTertiaryInternal: FC<ChildComponentWithDropdown<ButtonTertiaryProps>> = ({
  onClick = undefined,
  lIcon = null,
  rIcon = null,
  iconOptions = undefined,
  text = '',
  className = '',
  disabled = false,
  variant = ButtonTertiaryVariant.GREEN,
  size = ButtonTertiarySize.MD,
  width = ButtonTertiaryWidth.FIT,
  dropdownIcon = undefined,
  stickyIcons = false,
  loading = false,
  testId = 'button-tertiary',
}) => {
  const disabledOrLoading = disabled || loading;
  const styles = getStyles({
    variant,
    size,
    width,
    disabledOrLoading,
    hasText: text.length > 0,
    isClickable: !!onClick || !!dropdownIcon,
    stickyIcons,
  });
  const iconSize = size === ButtonTertiarySize.MD ? IconSize.LG : IconSize.MD;

  return (
    <button
      type="button"
      onClick={onClick}
      className={twMerge(clsx([styles.component, className]))}
      tabIndex={0}
      disabled={disabledOrLoading}
      data-test-element="button-tertiary"
      data-testid={testId}
    >
      {(lIcon || (loading && !(!lIcon && rIcon))) && (
        <Icon
          className={clsx({[styles.icon]: true, 'mr-[8px]': text.length > 0, 'animate-spin': loading})}
          svg={(loading ? IconSvg.ROTATE_RIGHT : lIcon) as IconSvg}
          variant={IconVariant.CONTOUR}
          size={iconSize}
          {...iconOptions}
        />
      )}
      {text && <span className={styles.text}>{text}</span>}
      {(rIcon || dropdownIcon || (loading && !lIcon && rIcon)) && (
        <Icon
          className={clsx({
            [styles.icon]: true,
            'ml-[8px]': text.length > 0,
            'animate-spin': loading && !lIcon && rIcon,
          })}
          svg={(loading && !lIcon && rIcon ? IconSvg.ROTATE_RIGHT : rIcon || dropdownIcon) as IconSvg}
          variant={IconVariant.CONTOUR}
          size={iconSize}
          {...iconOptions}
        />
      )}
    </button>
  );
};

export const ButtonTertiary: FC<ComponentWithDropdown<ButtonTertiaryProps>> = ({dropdown, ...props}) => (
  <WithDropdownList dropdown={dropdown}>
    <ButtonTertiaryInternal {...props} />
  </WithDropdownList>
);
