export const toLocaleNumberString = (
  input?: number | string | null,
  {
    emptyValue = '',
    displayZeroAsEmptyValue = false,
    decimalPlaces = 2,
    suffix = '',
  }: {
    emptyValue?: string | number;
    displayZeroAsEmptyValue?: boolean;
    decimalPlaces?: number;
    suffix?: string;
  } = {},
): string => {
  if (input == null || input === undefined || ((input === 0 || input === '0') && displayZeroAsEmptyValue))
    return String(emptyValue);

  const fractionDigits = decimalPlaces < 0 ? 0 : Math.floor(decimalPlaces);

  const numberAsString = Number(parseFloat(`${input}`.replace(/\s/g, '').replace(',', '.'))).toLocaleString('fr', {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });

  if (numberAsString === 'NaN') return String(emptyValue);

  return `${numberAsString}${suffix}`;
};
