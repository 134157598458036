import {useEffect, useState} from 'react';
import {ArrayElement} from '../types';

const getListWithUuid = <T extends ArrayElement<T>[]>(list: T): [string, ArrayElement<T>][] => {
  return list.map(el => [crypto.randomUUID(), el]);
};

export const useListState = <T extends ArrayElement<T>[]>(
  defaultValue: T,
): [valueWithKey: [string, ArrayElement<T>][], setValue: (nextValue: [string, ArrayElement<T>][]) => void] => {
  const [list, setList] = useState<[string, ArrayElement<T>][]>(getListWithUuid(defaultValue));

  useEffect(() => {
    setList(getListWithUuid<T>(defaultValue));
  }, [defaultValue]);

  return [list, setList];
};
