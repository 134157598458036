import {useContext} from 'react';
import {Level, LevelContext} from '../../external/context/LevelContext';
import {ToastProps} from './Toast';
import {useToastState} from './Toast.zustand';

export type SetToastProps = Omit<ToastProps, 'id'> & {id?: string};

export const setToast = ({id, ...toastProps}: SetToastProps) => {
  const uuid = crypto.randomUUID();
  useToastState.getState().setToast({...toastProps, id: id || uuid});

  return () => useToastState.getState().clearToast(id || uuid);
};

export const useToast = () => {
  const level = useContext(LevelContext);
  return (props: SetToastProps | SetToastProps[]) => {
    if (Array.isArray(props)) {
      props.map(_props => ({..._props, level: level + Level.Message})).forEach(setToast);
    } else {
      setToast({...props, legacy: false, level: level + Level.Message});
    }
  };
};
