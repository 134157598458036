import clsx from 'clsx';
import {FC, useContext} from 'react';
import {twMerge} from 'tailwind-merge';
import {TestableElement} from '../../external/types';
import {Toast} from './Toast';
import {useToastState} from './Toast.zustand';
import {Level, LevelContext} from '../../external/context/LevelContext';

export enum ToastManagerPosition {
  TOP_CENTER = 'TOP_CENTER',
  BOTTOM_RIGHT = 'BOTTOM_RIGHT',
}

export type ToastManagerProps = {
  className?: string;
  position?: ToastManagerPosition;
  globalDuration?: number;
} & TestableElement;

export const ToastManager: FC<ToastManagerProps> = ({
  className = undefined,
  position = ToastManagerPosition.BOTTOM_RIGHT,
  globalDuration = undefined,
  testId = undefined,
}) => {
  const level = useContext(LevelContext);
  const toastList = useToastState(state => state.toastList);
  const clearToast = useToastState(state => state.clearToast);

  const styles = {
    component: twMerge(
      clsx(`fixed w-0 h-0 overflow-visible flex z-[${level + Level.Message}]`, {
        'left-[50%] top-0 justify-center': position === ToastManagerPosition.TOP_CENTER,
        'max-lg:right-[16px] lg:right-[32px] xl:right-[64px] bottom-[128px] justify-end items-end':
          position === ToastManagerPosition.BOTTOM_RIGHT,
      }),
      className,
    ),
    box: clsx('absolute flex flex-col overflow-visible', {
      'items-center': position === ToastManagerPosition.TOP_CENTER,
      'items-end': position === ToastManagerPosition.BOTTOM_RIGHT,
    }),
  };

  return (
    <div className={styles.component} data-test-element="toast-manager" data-testid={testId}>
      <div className={styles.box}>
        {toastList.map(toastProps => (
          <Toast
            key={toastProps.id}
            duration={globalDuration}
            {...toastProps}
            className="mb-[8px]"
            onClose={id => {
              clearToast(id);
              if (toastProps.onClose) {
                toastProps.onClose(id);
              }
            }}
          />
        ))}
      </div>
    </div>
  );
};
