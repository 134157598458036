import {ReactElement, RefObject, useRef, FC, useState, useEffect, useCallback} from 'react';
import {debounce, isEqual} from 'lodash';
import {Subject, useSubject} from '../../helpers/Subject';

export type VirtualListProps = {
  parentRef: RefObject<HTMLDivElement | HTMLSpanElement>;
  scrollToIndex?: Subject<number>;
  // /**
  //  * No value provided (default): parent height will be red from parentRef
  //  * Positive value: will replace height red from parentRef. Use it to optimize performance.
  //  * Negative value: will be substracted from parentRef height. Use it if parent contains
  //  * any sticky or absolute positioned children besides the list.
  //  * */
  parentFixedHeight?: number;
  children: ReactElement | ReactElement[];
  padding?: number;
  rowHeightPx: number;
};

export const VirtualList: FC<VirtualListProps> = ({
  children,
  parentRef,
  scrollToIndex = undefined,
  rowHeightPx,
  padding = 30,
  parentFixedHeight = 0,
}) => {
  const isPristine = useRef<boolean>(true);
  const isScrolling = useRef<boolean>(false);
  const boxRef = useRef<HTMLDivElement>(null);

  const [, setRenderStamp] = useState<symbol>();
  const rerender = useCallback(() => {
    setRenderStamp(Symbol('rerender'));
  }, []);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const childrenArray = useRef(Array.from(children));
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const nextChildrenArr = Array.from(children);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const prevChildrenKeys = (childrenArray.current || []).map(el => el.key);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const nextChildrenKeys = nextChildrenArr.map(el => el.key);
    childrenArray.current = nextChildrenArr;

    if (!isEqual(prevChildrenKeys, nextChildrenKeys)) {
      isPristine.current = true;
    }
    rerender();
  }, [children]);

  const parentHeight = useRef<number>(0);
  const skip = useRef<number>(0);
  const paddedSkip = useRef<number>(0);
  const take = useRef<number>(0);
  const paddedTake = useRef<number>(0);

  const calcSkipTake = () => {
    const parentScroll = parentRef.current!.scrollTop;
    skip.current = Math.floor(parentScroll / rowHeightPx);
    take.current = Math.ceil(parentHeight.current / rowHeightPx);
  };

  const calcPaddedSkipTake = () => {
    paddedSkip.current = Math.max(0, skip.current - padding);
    paddedTake.current = Math.min(
      childrenArray.current.length,
      take.current + padding + Math.min(padding, skip.current),
    );
  };

  useEffect(() => {
    const handleResize = () => {
      parentHeight.current =
        parentFixedHeight > 0 ? parentFixedHeight : parentRef.current!.offsetHeight + parentFixedHeight;
      calcSkipTake();
      calcPaddedSkipTake();
    };

    handleResize();

    const callback = () => {
      handleResize();
      rerender();
    };
    const ro = new ResizeObserver(callback);
    ro.observe(parentRef.current!);
    return () => {
      ro.disconnect();
    };
  }, []);

  useEffect(() => {
    const setScrollState = debounce(
      (isScrollInProgress: boolean) => {
        isScrolling.current = isScrollInProgress;
        if (!isScrollInProgress) {
          rerender();
        }
      },
      100,
      {
        leading: true,
        trailing: true,
      },
    );

    const onScroll = (event: Event) => {
      event.preventDefault();
      isPristine.current = false;
      setScrollState(true);
      calcSkipTake();

      if (
        (parentRef.current?.scrollTop || 0) < paddedSkip.current * rowHeightPx ||
        (parentRef.current?.scrollTop || 0) + parentHeight.current >
          (paddedSkip.current + paddedTake.current) * rowHeightPx
      ) {
        calcPaddedSkipTake();
        rerender();
      }
    };

    const onScrollEnd = () => {
      setScrollState(false);
    };
    parentRef.current!.addEventListener('scroll', onScroll);
    parentRef.current!.addEventListener('scrollend', onScrollEnd);
    return () => {
      parentRef.current?.removeEventListener('scroll', onScroll);
      parentRef.current?.removeEventListener('scrollend', onScrollEnd);
    };
  }, []);

  useSubject<number>(scrollToIndex, nextIndex => {
    if (parentRef.current) {
      calcSkipTake();
      if (nextIndex * rowHeightPx < parentRef.current.scrollTop) {
        parentRef.current.scrollTo({top: nextIndex * rowHeightPx});
      }

      if ((nextIndex + 1) * rowHeightPx > parentRef.current.scrollTop + parentHeight.current) {
        parentRef.current.scrollTo({
          top: (nextIndex + 1) * rowHeightPx - parentHeight.current,
        });
      }
      rerender();
    }
  });

  useEffect(() => {
    const boxEl = boxRef.current;

    const mo = new MutationObserver(() => {
      if (!boxEl || boxEl.parentElement === null) {
        return;
      }
      boxEl.style.width = boxEl.parentElement.style.width || `${boxEl.parentElement.getBoundingClientRect().width}px`;
    });

    if (boxEl && boxEl.parentElement !== null) {
      mo.observe(boxEl.parentElement, {
        attributes: true,
        attributeFilter: ['style', 'class'],
        subtree: false,
      });
    }

    return () => mo.disconnect();
  }, []);

  return (
    <div ref={boxRef} className="relative" style={{height: `${childrenArray.current.length * rowHeightPx}px`}}>
      <div
        className="w-full top-0 z-[1] relative"
        style={{
          transform: `translate(${0}px, ${isPristine.current ? 0 : paddedSkip.current * rowHeightPx}px)`,
        }}
      >
        {childrenArray.current.slice(paddedSkip.current, paddedSkip.current + paddedTake.current)}
      </div>
    </div>
  );
};
