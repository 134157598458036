import {FC, PropsWithChildren, useCallback, useEffect, useState} from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/pl';
import {DateStrFormat, InputDate, InputDateWidth, InputWidth, Label} from '@symfonia/brandbook';
import {DATE_DMY} from '../../../../../../libs/brandbook/src/external/types';
import {europeanDateFormatter} from 'libs/symfonia-ksef-components/src/helpers/europeanDateFormatter';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
export interface DatePickerProps extends PropsWithChildren {
  setDate: (value?: {from?: Date; to?: Date; specific?: Date}) => void;
  startDate?: Date;
  specificDate?: Date;
  endDate?: Date;
  singleDateLabel?: string;
  rangeStartDateLabel: string;
  singleDateInputLabel: string;
  rangeDateInputLabel?: string;
  rangeDateFromLabel?: string;
  rangeDateToLabel?: string;
  setValidationError?: (hasError: boolean) => void;
  testId?: string;
  maxSpanMonths?: number;
}

export const DatePicker: FC<DatePickerProps> = ({
  startDate,
  endDate,
  setDate,
  specificDate,
  singleDateLabel,
  rangeStartDateLabel,
  singleDateInputLabel,
  rangeDateInputLabel,
  rangeDateFromLabel,
  rangeDateToLabel,
  setValidationError,
  testId,
  maxSpanMonths,
}) => {
  const [hasError, setHasError] = useState(false);
  const specificDateValue = specificDate && dayjs(specificDate)?.format(DateStrFormat.DD_MM_YYYY) as DATE_DMY;
  const today = dayjs().toDate();

  const onChangeClearValidation = useCallback(() => {
    setValidationError?.(false);
    setHasError(false);
  }, [setValidationError]);

  
  const isSpanExceeded = (start: dayjs.Dayjs, end: dayjs.Dayjs, maxYears?: number) => {
    if (!maxYears) return false;
    const maxAllowedEnd = start.add(maxYears, 'month').subtract(1, 'day');
    return end.isAfter(maxAllowedEnd);
  };

  useEffect(() => {
    if (!startDate && !endDate) return;

    const start = dayjs(startDate);
    const end = dayjs(endDate);

    if (!dayjs(startDate).isBefore(dayjs(endDate))) {
      setValidationError?.(true);
      setHasError(true);
      return;
    }
    const isInvalidOrder = !start.isBefore(end);
    const isDateExceeded = isSpanExceeded(start, end, maxSpanMonths);

    if (isInvalidOrder || isDateExceeded) {
      setValidationError?.(true);
      setHasError(true);
      return;
    }
    onChangeClearValidation();
  }, [startDate, endDate, maxSpanMonths]);

  const handleOnChange = (date: DATE_DMY, type: 'FROM' | 'TO') => {
    const newDate = europeanDateFormatter(date);

    if (type === 'FROM' && endDate && maxSpanMonths) {
      const proposedStart = dayjs(newDate);
      const currentEnd = dayjs(endDate);

      if (isSpanExceeded(proposedStart, currentEnd)) {
        const adjustedEnd = proposedStart.add(maxSpanMonths, 'month').add(1, 'day').toDate();
        setDate({from: newDate, to: adjustedEnd, specific: undefined});
        return;
      }
    }

    if (type === 'TO' && startDate && maxSpanMonths) {
      const currentStart = dayjs(startDate);
      const proposedEnd = dayjs(newDate);
      
      if (isSpanExceeded(currentStart, proposedEnd, maxSpanMonths)) {
        const adjustedStart = proposedEnd.subtract(maxSpanMonths, 'month').add(1, 'day').toDate();
        setDate({from: adjustedStart, to: newDate, specific: undefined});
        return;
      }
    }

    setDate({from: type === 'FROM' ? newDate : startDate, to: type === 'TO' ? newDate : endDate, specific: undefined});
  };

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'pl'} />
      <InputDate
        label={singleDateLabel}
        placeholder={singleDateInputLabel}
        width={InputWidth.FULL}
        value={specificDateValue || '' as DATE_DMY}
        onChange={newDate => {
          const europeanDate = europeanDateFormatter(newDate);
          europeanDate !== specificDate ? setDate({specific: europeanDate}) : setDate(undefined);
          setHasError(false);
          setValidationError?.(false);
        }}
        testId={`${testId}-SpecificDate`}
      />
      <div className="flex flex-col mt-2 gap-[8px]">
        {rangeDateInputLabel && <Label text={rangeDateInputLabel}/>}
        <div className="flex gap-[8px]">
          <InputDate
            width={InputDateWidth.FIT}
            isError={hasError}
            placeholder={rangeDateFromLabel || 'Od'}
            onChange={date => handleOnChange(date, 'FROM')}
            className="mb-[8px]"
            value={startDate !== undefined ? (dayjs(startDate)?.format(DateStrFormat.DD_MM_YYYY) as DATE_DMY) : ('' as DATE_DMY)}
            testId={`${testId}-DateFrom`}
            maxDate={today}
          />
          <InputDate
            width={InputDateWidth.FIT}
            isError={hasError}
            placeholder={rangeDateToLabel || 'Do'}
            onChange={date => handleOnChange(date, 'TO')}
            className="mb-[8px]"
            value={endDate !== undefined ? (dayjs(endDate)?.format(DateStrFormat.DD_MM_YYYY) as DATE_DMY) : ('' as DATE_DMY)}
            testId={`${testId}-DateTo`}
            maxDate={today}
          />
        </div>
      </div>
    </div>
  );
};
